/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listOrderPurchaseOrder = params => axios({
    url: '/api/order/purchase/orderPurchaseOrder/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addOrderPurchaseOrder = params => axios({
    url: '/api/order_purchase_order/system/orderPurchaseOrder/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editOrderPurchaseOrder = params => axios({
    url: '/api/order_purchase_order/system/orderPurchaseOrder/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delOrderPurchaseOrder = params => axios({
    url:'/api/order_purchase_order/system/orderPurchaseOrder/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdOrderPurchaseOrder = params => axios({
    url: '/api/order/purchase/orderPurchaseOrder/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
